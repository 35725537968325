import React, {Component} from 'react'
import Highlighter from 'react-highlight-words'
import {
    Modal,
    Typography,
    Divider,
    Radio,
    Row,
    Col,
    Button,
    AutoComplete,
    Input,
    Tooltip,
    Select,
    Spin,
    Slider,
    InputNumber,
    Table,
    Switch,
    notification
} from 'antd'
import GoogleButton from 'react-google-button'
import {connect} from 'react-redux'
import _ from 'lodash'
import htmx from "htmx.org";
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import {CloseCircleTwoTone, ExportOutlined, HeatMapOutlined, SearchOutlined} from "@ant-design/icons";

import {LOGIN_ENDPOINT, PROTEIN_SEARCH_MIN, BACKEND_URL, EXPORT_EXPERIMENTS_URL, ERROR_COLOR} from '../shared/constants'
import {SMILESChemicalStructure} from '../shared/components'
import {Volcano, Heatmap} from '.'
import {
    getResultDataRequest,
    getAllCompounds,
    getAllProteins,
    setSearchedExperiment
} from '../features/experiments/experimentsActions'
import {
    setHeatmapMax,
    setHeatmapMin,
    setVolcanoX,
    setVolcanoYMin,
    setVolcanoYMax,
    setVolcanoP,
    setVolcanoFC,
    setVolcanoUnique,
    setVolcanoType,
    setClickedAsync,
    setLoading,
    addCustomProtein,
    addCustomCompound,
    setShowLabel,
    setMinCount,
    setMinReplicates
} from '../features/results/resultsActions'
import protein from '../assets/protein.png'
import compound from '../assets/compound.png'
import {toggleLoginModal} from "../features/token/tokenActions";

const {Option} = Select
const COMPOUND_QUERY = 'compound'
const PROTEIN_QUERY = 'protein'
const TREATMENT_QUERY = 'treatment'

const SQUARE = 'square'
const HYPERBOLIC = 'hyperbolic'
const X_SLIDER_MAX = 0
const X_SLIDER_MIN = -10
const Y_SLIDER_MAX = 5
const Y_SLIDER_MIN = -5

const DEFAULT_UNIQUE = 2
const DEFAULT_FC = 2
const DEFAULT_P = -3
const DEFAULT_TYPE = SQUARE
const PRECISION = 4

class Home extends Component {
    constructor(props) {
        super(props)
        const {results, addCustomProtein, addCustomCompound} = this.props
        const {
            volcanoX,
            volcanoYMin,
            volcanoYMax,
            volcanoP,
            volcanoFC,
            volcanoUnique,
            volcanoType
        } = results

        const {Text} = Typography
        this.columns = [
            {
                title: 'Add to Custom Heatmap',
                key: 'custom_heatmap',
                width: 200,
                render: (text, record) => (
                    <Button icon={<HeatMapOutlined />}
                            style={{marginLeft: 63}}
                            onClick={() => {
                                const {meta} = this.props.experiments.searchedExperiment;

                                addCustomProtein({
                                    id: `${record.accession_name} (${record.gene_symbol})`,
                                    key: String(record.accession_id)
                                });

                                addCustomCompound({
                                    id: `${record.treatment_name} (${meta.name})`,
                                    key: `${record.treatment_id}-${meta.id}`
                                });

                                notification.success({
                                    placement: 'bottomRight',
                                    message: 'Success',
                                    description: 'Successfully added to custom heatmap!',
                                    style: {backgroundColor: ERROR_COLOR}
                                })
                            }}
                    />
                )
            },
            {
                title: 'Accession',
                dataIndex: 'accession_name',
                key: 'accession_name',
                sorter: (a, b) => this.sorterHelper(a.accession_name, b.accession_name),
                ...this.getColumnSearchProps('accession_name'),
            },
            {
                title: 'Gene Symbol',
                dataIndex: 'gene_symbol',
                key: 'gene_symbol',
                sorter: (a, b) => this.sorterHelper(a.gene_symbol, b.gene_symbol),
                ...this.getColumnSearchProps('gene_symbol'),
            },
            {
                title: 'Compound Name',
                dataIndex: 'compound_name',
                key: 'compound_name',
                sorter: (a, b) => this.sorterHelper(a.compound_name, b.compound_name),
                ...this.getColumnSearchProps('compound_name'),
            },
            {
                title: 'Treatment Name',
                dataIndex: 'treatment_name',
                key: 'treatment_name',
                sorter: (a, b) => this.sorterHelper(a.treatment_name, b.treatment_name),
                ...this.getColumnSearchProps('treatment_name'),
            },
            {
                title: <Text>Log<sub>2</sub> Fold Change</Text>,
                dataIndex: 'log_fc',
                key: 'log_fc',
                sorter: (a, b) => a.log_fc - b.log_fc,
                ...this.getColumnSearchProps('log_fc'),
                render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
            },
            {
                title: <Text>Log<sub>10</sub> P-Value</Text>,
                dataIndex: 'neg_log10_p_value',
                key: 'neg_log10_p_value',
                sorter: (a, b) => a.neg_log10_p_value - b.neg_log10_p_value,
                ...this.getColumnSearchProps('neg_log10_p_value'),
                render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
            },
            {
                title: `Pi`,
                dataIndex: 'pi',
                key: 'pi',
                sorter: (a, b) => a.pi - b.pi,
                ...this.getColumnSearchProps('pi'),
                render: (text) => Number.parseFloat(text).toPrecision(PRECISION),
            },
        ]

        this.queryId = -1;
        this.queryType = COMPOUND_QUERY;

        this.state = {
            visible: false,
            queryType: this.queryType,
            searchValue: '',
            loading: false,
            heatmapColors: [
                'Greys', 'YlGnBu', 'Greens', 'YlOrRd',
                'Bluered', 'RdBu', 'Reds', 'Blues', 'Picnic',
                'Rainbow', 'Portland', 'Jet', 'Hot', 'Blackbody',
                'Earth', 'Electric', 'Viridis', 'Cividis'
            ],
            heatmapColorSelected: 'RdBu',
            heatmapMin: -10,
            heatmapMax: 10,
            volcanoX,
            volcanoYMin,
            volcanoYMax,
            volcanoP: volcanoP ? volcanoP : DEFAULT_P,
            volcanoFC: volcanoFC ? volcanoFC : DEFAULT_FC,
            volcanoUnique,
            volcanoType: volcanoType ? volcanoType : DEFAULT_TYPE,
            hits: [],
            hitData: [],
            minCount: 2,
            minReplicates: 1
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        htmx.process(document.body);
        if (this.state.queryType !== prevState.queryType) {
            this.updateChartsAndTable();
        }
    }

    componentDidMount() {
        const {token, getAllCompounds, getAllProteins, setVolcanoUnique, setSearchedExperiment} = this.props

        setVolcanoUnique(DEFAULT_UNIQUE)
        setSearchedExperiment(null)

        if (token) {
            let promises = [
                getAllCompounds(token),
                getAllProteins(token),
            ]
            Promise.all(promises).then(value =>
                this.setState({
                    loading: false,
                })
            );
        }
    }

    handleOk = e => {
        this.props.toggleLoginModal();
        window.location.href = LOGIN_ENDPOINT;
    };

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleTableSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),

        filterIcon: filtered => (
            <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>
        ),

        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()) : '',

        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },

        render: text => (
            (this.state.searchedColumn === dataIndex && text) ?
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
                : text
        ),
    });

    handleTableSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };

    sorterHelper = (a, b) => {
        if (a.value) {
            return b.value ? a.value.localeCompare(b.value) : -1
        } else if (b.value) {
            return a.value ? b.value.localeCompare(a.value) : 1
        } else {
            return -1
        }
    }

    handleCancel = e => {
        this.props.toggleLoginModal()
    };

    handleSearch = () => {
        const { searchValue, queryType, minCount, minReplicates } = this.state;
        const { getResultDataRequest, token, experiments, setVolcanoType, setVolcanoUnique, setVolcanoFC, setVolcanoP } = this.props;
        const { allProteins, allCompounds } = experiments;

        const queryTarget = _.filter(
            queryType === COMPOUND_QUERY ? allCompounds : allProteins,

            record => {
                let regExp = /\(([^)]+)\)/
                if (queryType === COMPOUND_QUERY) {
                    return record.name === searchValue
                } else {
                    let matches = regExp.exec(searchValue)
                    return Boolean(matches) && record.name === matches[1]
                }
            }
        )

        if (Boolean(queryTarget)) {
            const queryObject = queryTarget[0]

            if (Boolean(queryObject)) {
                const target = queryObject[queryType === COMPOUND_QUERY ? 'compound_id' : 'accession_id'];

                this.setState({
                    loading: true
                });

                setVolcanoUnique(DEFAULT_UNIQUE);
                setVolcanoFC(DEFAULT_FC);
                setVolcanoP(DEFAULT_P);
                setVolcanoType(DEFAULT_TYPE);

                getResultDataRequest(token, target, queryType, minCount, minReplicates)
                    .then((resp) => {
                        if (resp) {
                            this.updateChartsAndTable();
                        } else {
                            this.setState({loading: false})
                        }
                    });
            }
        }
    }

    updateChartsAndTable(volcanoType=DEFAULT_TYPE, volcanoP=DEFAULT_P, volcanoFC=DEFAULT_FC, queryType=this.state.queryType, searchValue=this.state.searchValue, minCount=this.state.minCount, minReplicataes=this.state.minReplicates) {
        const resultData = this.getResults();
        const hits = this.getHits(resultData);
        const hitData = this.getHitData(resultData);

        const logFCValues = _.map(resultData, 'log_fc');
        const volcanoX = 0;

        const volcanoYMin = logFCValues.length ? _.min(logFCValues) - 0.25 : 0;
        const volcanoYMax = logFCValues.length ? _.max(logFCValues) + 0.25 : 0;

        this.setState({
            loading: false,
            queryType,
            searchValue,
            hits,
            hitData,
            volcanoX,
            volcanoYMax,
            volcanoYMin,
            volcanoType,
            volcanoP,
            volcanoFC,
            minCount,
            minReplicataes
        });
    }

    updatePageData(updateState=true) {
        const { minCount, minReplicates, queryType } = this.state;
        const { searchedExperiment } = this.props.experiments;
        const treatmentIds = _.map(this.props.experiments.searchedExperiment.meta.compound, compound => compound.treatment_id);
        const queryId = queryType === COMPOUND_QUERY ?
            searchedExperiment.compound.compound_id :
            treatmentIds[0];

        getResultDataRequest(
            this.props.token,
            queryId,
            queryType,
            minCount,
            minReplicates,
            updateState
        ).then(
            (resp) => {
                if (resp) {
                    this.updateChartsAndTable(null);
                } else {
                    this.setState({loading: false})
                }
            }
        );
    }

    onExperimentDropdownChange = (experimentId) => {
        const { setVolcanoType, setVolcanoP, setVolcanoFC } = this.props;

        this.setState({
            loading: true
        });

        setVolcanoFC(DEFAULT_FC);
        setVolcanoP(DEFAULT_P);
        setVolcanoType(DEFAULT_TYPE);

        this.updatePageData();
    }

    onTreatmentDropdownChange = (inputValue) => {
        const { setVolcanoType, setVolcanoFC, setVolcanoP } = this.props;

        this.setState({loading: true});

        this.treatment = _.split(inputValue, '/')[1];

        setVolcanoFC(DEFAULT_FC);
        setVolcanoP(DEFAULT_P);
        setVolcanoType(DEFAULT_TYPE);

        this.updatePageData(true);
    }

    filterSearch() {
        const {queryType, searchValue} = this.state;
        const {allProteins, allCompounds} = this.props.experiments

        let results = [];

        if (queryType === COMPOUND_QUERY) {
            results = _.map(allCompounds, (compound) => {
                return { value: compound.name };
            }).sort(this.sorterHelper)
        } else if (searchValue && searchValue.length >= PROTEIN_SEARCH_MIN) {
            results = _.map(allProteins, (record) => {
                return { value: `${record['gene_symbol']} (${record['name']})` };
            }).sort(this.sorterHelper);
        }

        return results;
    }

    setQueryType = (e) => {
        this.setState({
            queryType: e.target.value
        })
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSearch();
        }
    }

    getExperimentOptions() {
        let options = []
        const {searchedExperiment} = this.props.experiments
        if (Boolean(searchedExperiment)) {
            const {experiments} = 'compound' in searchedExperiment ? searchedExperiment.compound : searchedExperiment.protein

            experiments.forEach(element => {
                options.push(<Option key={element.id} value={element.id}>{element.name}</Option>)
            })
        }
        return options
    }

    getSecondaryOptions() {
        let options = []
        let added = []
        const { searchedExperiment } = this.props.experiments;
        const secondaryOptions = searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.compound : []

        secondaryOptions.forEach(element => {
            if (!(added.includes(`${element.id}/${element.treatment_id}`))) {
                added.push(`${element.id}/${element.treatment_id}`)
                options.push(<Option key={`${element.id}/${element.treatment_id}`}
                                     value={`${element.id}/${element.treatment_id}`}>{element.treatment_name} ({element.name})</Option>)
            }
        })
        return options
    }

    getTertiaryOptions() {
        let options = []
        const {searchedExperiment} = this.props.experiments

        if (Boolean(searchedExperiment)) {
            const {experiments} = 'compound' in searchedExperiment ? searchedExperiment.compound : searchedExperiment.protein

            experiments.forEach(element => {
                options.push(<Option key={element.id} value={element.id}>{element.cell_line} ({element.name})</Option>)
            })
        }
        return options
    }

    getHeatmapStyleOptions() {
        let options = []
        const {heatmapColors} = this.state

        heatmapColors.forEach(element => {
            options.push(<Option key={element} value={element}>{element}</Option>)
        })
        return options
    }

    getResults() {
        const {experiments, results} = this.props
        const {searchedExperiment} = experiments

        return searchedExperiment ?
            !searchedExperiment.meta.filter_label ?
                _.filter(searchedExperiment.data, obj => {
                    try {
                        return obj.unique_peptides >= results.volcanoUnique
                    } catch {
                        return false
                    }
                })
                : searchedExperiment.data
            : []
    }

    getHits(resultData) {
        if (resultData !== undefined && resultData.length > 0) {
            return _.filter(resultData, obj => this.isHit(obj));
        }
        return [];
    }

    getHitData(resultData) {
        return _.map(resultData, obj => this.isHit(obj))
    }

    isHit(record) {
        const {volcanoFC, volcanoP, volcanoType, volcanoUnique} = this.props.results
        const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || _.isNil(volcanoType)

        if (useDefault) {
            return record.default_hit && record.unique_peptides >= volcanoUnique
        } else {
            if (volcanoType === SQUARE) {
                return (record.neg_log10_p_value <= volcanoP) &&
                    (record.log_fc >= 0 ?
                        record.log_fc >= Math.abs(Math.log2(volcanoFC)) :
                        record.log_fc <= (Math.abs(Math.log2(volcanoFC)) * -1))

            } else {
                const piUp = Math.abs(Math.log2(volcanoFC) * volcanoP)
                const piDown = Math.abs(Math.log2(volcanoFC) * volcanoP) * -1

                return record.log_fc >= 0 ? record.pi >= piUp : record.pi <= piDown
            }
        }
    }

    render() {
        const {Title, Paragraph, Text} = Typography;
        const { searchValue, loading, heatmapColorSelected, hits, hitData } = this.state;
        const {
            experiments, setHeatmapMax, setHeatmapMin, results, setClickedAsync, setLoading,
            setVolcanoFC, setVolcanoP, setVolcanoType, setVolcanoUnique, setVolcanoX, setVolcanoYMin, setVolcanoYMax,
            addCustomProtein, addCustomCompound, minCount, minReplicates
        } = this.props;
        const {searchedExperiment} = experiments;
        const filterLabel = searchedExperiment ? searchedExperiment.meta.filter_label : false;

        if (results && results.clicked) {
            setClickedAsync(false).then((resp) => {
                    setVolcanoUnique(DEFAULT_UNIQUE);
                    setVolcanoFC(DEFAULT_FC);
                    setVolcanoP(DEFAULT_P);
                    setVolcanoType(DEFAULT_TYPE);
                    setLoading(false);

                    if (filterLabel) {
                        this.setState({
                            queryType: PROTEIN_QUERY,
                            volcanoType: DEFAULT_TYPE,
                            volcanoP: DEFAULT_P,
                            volcanoFC: DEFAULT_FC,
                            hits: [],
                            hitData: [],
                            searchValue: searchedExperiment.meta.filter_label + " (" + searchedExperiment.meta.filter_name + ")"
                        });
                    } else {
                        this.updateChartsAndTable(null, DEFAULT_P, DEFAULT_FC, minCount, minReplicates, searchedExperiment.compound.compound_name, COMPOUND_QUERY);
                    }
                }
            )
        }

        if (loading || results.loading) {
            return <Spin/>
        }

        if (this.props.token) {
            return <>
                <Typography>
                    <Title level={3}>
                        <SearchOutlined/> {`Search${Boolean(searchedExperiment) ? ': ' + (!searchedExperiment.meta.filter_label ? searchedExperiment.meta.filter_name : searchedExperiment.meta.filter_label.split(';')[0] + " (" + searchedExperiment.meta.filter_name + ")") : ''}`}
                    </Title>
                </Typography>
                <Divider/>
                <Row gutter={8}>
                    <Col span={6}>
                        <Radio.Group size='large' value={this.state.queryType} onChange={this.setQueryType}>
                            <Radio.Button value={COMPOUND_QUERY}>Compound</Radio.Button>
                            <Radio.Button value={PROTEIN_QUERY}>Protein</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Col span={12}>
                        <AutoComplete
                            style={{
                                width: '100%',
                                size: 'large'
                            }}
                            value={searchValue}
                            onChange={value => this.setState({searchValue: value})}
                            options={this.filterSearch()}
                            placeholder={`Enter ${this.state.queryType}${this.state.queryType === PROTEIN_QUERY ? ' - minimum ' + String(PROTEIN_SEARCH_MIN) + ' characters' : ''}`}
                            filterOption={(inputValue, option) => {
                                return option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }}
                        >
                            <Input
                                onKeyDown={this._handleKeyDown}
                                value={searchValue}
                                suffix={
                                    <>
                                        <CloseCircleTwoTone
                                            style={!Boolean(searchValue) ? {display: 'none'} : {marginRight: 5}}
                                            onClick={() => this.setState({searchValue: ''})}
                                        />
                                        <Tooltip title='Search'>
                                            <SearchOutlined
                                                onClick={this.handleSearch}
                                                style={!Boolean(searchValue) ? {display: 'none'} : {}}
                                            />
                                        </Tooltip>
                                    </>
                                }
                            />
                        </AutoComplete>
                    </Col>
                    <Col span={6}>
                        <Button disabled={!Boolean(searchedExperiment)} style={{float: 'right'}} size='large'
                                icon={<ExportOutlined />}
                                onClick={() => {
                                    const {token, experiments,} = this.props
                                    const {queryType} = this.state
                                    const {searchedExperiment} = experiments
                                    const target = searchedExperiment[TREATMENT_QUERY in searchedExperiment ? TREATMENT_QUERY : COMPOUND_QUERY in searchedExperiment ? COMPOUND_QUERY : PROTEIN_QUERY]
                                    const exportType = filterLabel ? PROTEIN_QUERY : TREATMENT_QUERY
                                    const compoundId = !filterLabel ? target.compound_id : 1

                                    window.open(`${BACKEND_URL}${EXPORT_EXPERIMENTS_URL}${exportType}/${target.id}/${queryType !== PROTEIN_QUERY ? searchedExperiment.meta.id : ''}?token=${token}&id=${compoundId}`, "_blank")
                                }}>
                            Export
                        </Button>
                        <Button disabled={!Boolean(searchedExperiment) || filterLabel} style={{float: 'right'}}
                                size='large' icon={<HeatMapOutlined />}
                                onClick={() => {
                                    const {experiments,} = this.props
                                    const {searchedExperiment} = experiments
                                    const {meta} = searchedExperiment
                                    hits.forEach(record => {
                                            addCustomProtein({
                                                id: `${record.accession_name} (${record.gene_symbol})`,
                                                key: String(record.accession_id)
                                            })
                                            addCustomCompound({
                                                id: `${record.treatment_name} (${meta.name})`,
                                                key: `${record.treatment_id}-${meta.id}`
                                            })
                                        }
                                    )
                                    notification.success({
                                        placement: 'bottomRight',
                                        message: 'Success',
                                        description: 'Successfully added to custom heatmap!',
                                        style: {backgroundColor: ERROR_COLOR}
                                    })
                                }
                                }>
                            Add to Heatmap
                        </Button>
                    </Col>
                </Row>
                <Row gutter={8} style={!Boolean(searchedExperiment) ? {marginTop: 15, display: 'block'} : {display: 'none'}}>
                    {this.state.queryType === COMPOUND_QUERY
                        ? <Text>Search by <Text strong>Compound</Text> to find whole cell proteomics data for your
                            compound of interest.</Text>
                        : <Text>Search by <Text strong>Protein</Text> gene symbol or Uniprot accession number to find
                            experiments where your protein of interest was measured.</Text>
                    }
                    <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                    <Paragraph>
                        The goal of this proteomics webtool is to provide a growing open-access resource for
                        quantitative global proteome responses from cell lines treated with various compounds and novel
                        chemical probes.
                    </Paragraph>
                    <Paragraph>
                        <Text strong>This webtool can be used for the:</Text>
                    </Paragraph>
                    <Paragraph>
                        &#8226; Identification of PROTEIN abundance changes in response to different compound treatments
                    </Paragraph>
                    <Paragraph>
                        <img className='protein-img' src={protein} alt='Demo scatter 1'/>
                    </Paragraph>
                    <Paragraph>
                        &#8226; Identification of COMPOUNDS inducing abundance changes of particular proteins
                    </Paragraph>
                    <Paragraph>
                        <img className='compound-img' src={compound} alt='Demo scatter 2'/>
                    </Paragraph>
                </Row>
                <Row gutter={8} style={Boolean(searchedExperiment) ? {marginTop: 15} : {display: 'none'}}>
                    <Col span={6} style={searchedExperiment && searchedExperiment.protein ? {display: 'none'} : {}}>
                        <div className='searchDropdownDiv'>
                            <Tooltip title='Multi-plex experiments including this compound'><span
                                className='selectTitle'>Experiment</span></Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={searchedExperiment ? searchedExperiment.meta.name : ''}
                                optionFilterProp="children"
                                onChange={this.onExperimentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                }
                            >
                                {this.getExperimentOptions()}
                            </Select>
                            <Tooltip title='All treatments in this multi-plex experiment'><span
                                className='selectTitle'>Treatment</span></Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={searchedExperiment && searchedExperiment.compound ? searchedExperiment.compound.name : ''}
                                optionFilterProp="children"
                                onChange={this.onTreatmentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {this.getSecondaryOptions()}
                            </Select>
                            <Tooltip title='All cell lines with this compound'>
                                <span className='selectTitle'>
                                    Cell Line
                                </span>
                            </Tooltip>
                            <Select
                                className={'filterDropdown'}
                                value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.cell_line : ''}
                                optionFilterProp="children"
                                onChange={this.onExperimentDropdownChange}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {this.getTertiaryOptions()}
                            </Select>
                            <span className='selectTitle'>Concentration (µM)</span>
                            <Input
                                className='searchDropdown'
                                value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.concentration : ''}
                                disabled={true}
                                style={{marginLeft: 10, width: '92%'}}
                            >
                            </Input>
                            <span className='selectTitle'>Time (min)</span>
                            <Input
                                className='searchDropdown'
                                value={searchedExperiment && searchedExperiment.meta ? searchedExperiment.meta.time : ''}
                                disabled={true}
                                style={{marginLeft: 10, width: '92%'}}
                            >
                            </Input>
                            <span className='selectTitle'>Counts / Replicate</span>
                            <Row>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={1}
                                        step={1}
                                        value={this.state.minCount}
                                        onChange={value => {
                                            if (!isNaN(value) && value !== null) {
                                                setMinCount(value);
                                                this.setState({minCount: value}, this.updatePageData);
                                            }
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={1}
                                        step={1}
                                        value={this.state.minReplicates}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                setMinReplicates(value);
                                                this.setState({minReplicates: value}, this.updatePageData);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className='customControls'>
                            <span className='selectTitle'>Compound Chemical Structure</span>
                            <SMILESChemicalStructure
                                chemicalStructureSVG={searchedExperiment && searchedExperiment.compound ? searchedExperiment.compound.chemicalStructureSVG : null}/>
                        </div>
                        <div className='searchDropdownDiv'>
                            <span className='selectTitle'>Show Gene Symbol {<br/>}</span>
                            <div style={{marginTop: 5, marginBottom: -5, display: 'flex', justifyContent: 'center'}}>
                                <Switch checked={this.props.results.showLabel} onChange={(e) => {
                                    this.props.setShowLabel(e)
                                }}/>
                            </div>
                            {<br/>}

                            <span className='cutoffTitle'>Hit Cut-off Settings{<br/>}</span>
                            <div style={{height: 10}}/>
                            <span className='selectTitle'>Cut-off Shape{<br/>}</span>
                            <div style={{marginTop: 5, marginBottom: -5, display: 'flex', justifyContent: 'center'}}>
                                <Radio.Group onChange={e => {
                                    this.setState({volcanoType: e.target.value})
                                    setVolcanoType(e.target.value)
                                }} value={this.state.volcanoType}>
                                    <Radio.Button value={HYPERBOLIC}>
                                        <Tooltip title={<Text style={{color: 'white'}}>Cutoff defined by k =
                                            log<sub>10</sub> (P-Value) x log<sub>2</sub>(Fold Change)</Text>}>
                                            Hyperbolic
                                        </Tooltip>
                                    </Radio.Button>

                                    <Radio.Button value={SQUARE}>
                                        <Tooltip title={<Text style={{color: 'white'}}>Cutoffs defined by x =
                                            log<sub>10</sub> (P-Value) and y = log<sub>2</sub>(Fold Change)</Text>}>
                                            Square
                                        </Tooltip>
                                    </Radio.Button>
                                </Radio.Group>
                            </div>
                            {<br/>}

                            <span className='selectTitle'><Tooltip
                                title='Log-transformed P-value from a moderated t-test'>Log<sub>10</sub> P-Value</Tooltip></span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={1}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        onChange={value => this.setState({volcanoP: value})}
                                        value={this.state.volcanoP}
                                        onChangeComplete={value => {
                                            setVolcanoP(value)
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        step={0.1}
                                        value={this.state.volcanoP}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                this.setState({volcanoP: value ? value : 0})
                                                AwesomeDebouncePromise(setVolcanoP(value ? value : 0), 500)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'><Tooltip
                                title='Absolute fold change in protein abundance for treatment vs control'>Fold Change</Tooltip></span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={0.25}
                                        min={1}
                                        max={10}
                                        value={this.state.volcanoFC}
                                        onChange={value => this.setState({volcanoFC: value})}
                                        onChangeComplete={value => {
                                            setVolcanoFC(value)
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={0}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={this.state.volcanoFC}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                this.setState({volcanoFC: value ? value : 0})
                                                AwesomeDebouncePromise(setVolcanoFC(value ? value : 0), 500)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'><Tooltip
                                title='Number of unique peptides used to identify this protein'>Unique Peptides</Tooltip></span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={1}
                                        min={0}
                                        max={50}
                                        value={this.state.volcanoUnique}
                                        onChange={value => this.setState({volcanoUnique: value})}
                                        onChangeComplete={value => {
                                            setVolcanoUnique(value)
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={0}
                                        max={50}
                                        value={this.state.volcanoUnique}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                this.setState({volcanoUnique: value ? value : 0})
                                                setVolcanoUnique(value ? value : 0)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'>X-axis Limit</span>
                            <Row>
                                <Col span={16}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        step={0.1}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        value={this.state.volcanoX}
                                        onChange={value => this.setState({volcanoX: value})}
                                        onChangeComplete={value => {
                                            setVolcanoX(value)
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={X_SLIDER_MIN}
                                        max={X_SLIDER_MAX}
                                        step={0.1}
                                        value={this.state.volcanoX}
                                        onChange={value => {
                                            if (!isNaN(value)) {
                                                this.setState({volcanoX: value ? value : 0})
                                                AwesomeDebouncePromise(setVolcanoX(value ? value : 0), 500)
                                            } else {
                                                console.log(`Bad field: ${value}`)
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                            <span className='selectTitle'>Y-axis Limits</span>
                            <Row>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={this.state.volcanoYMin}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    this.setState({volcanoYMin: rounded})
                                                    setVolcanoYMin(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Slider
                                        style={{marginLeft: 16}}
                                        range={true}
                                        step={0.1}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        value={[this.state.volcanoYMin, this.state.volcanoYMax]}
                                        onChange={value => this.setState({
                                            volcanoYMin: value[0],
                                            volcanoYMax: value[1]
                                        })}
                                        onChangeComplete={value => {
                                            setVolcanoYMin(value[0])
                                            setVolcanoYMax(value[1])
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={Y_SLIDER_MIN}
                                        max={Y_SLIDER_MAX}
                                        step={0.1}
                                        value={this.state.volcanoYMax}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    this.setState({volcanoYMax: rounded})
                                                    setVolcanoYMax(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                            </Row>

                        </div>
                        <div className='searchDropdownDiv'>
                            <span className='selectTitle'>Heatmap Color Bar Range</span>
                            <Select
                                className={'filterDropdown'}
                                value={heatmapColorSelected}
                                optionFilterProp="children"
                                onChange={val => this.setState({heatmapColorSelected: val})}
                                filterOption={(input, option) => {
                                    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            >
                                {this.getHeatmapStyleOptions()}
                            </Select>
                            <span className='selectTitle'>Heatmap Range:</span>
                            <Row>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={-10}
                                        max={10}
                                        step={0.1}
                                        value={this.state.heatmapMin}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    this.setState({heatmapMin: rounded})
                                                    setHeatmapMin(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                                <Col span={8}>
                                    <Slider
                                        range={true}
                                        value={[this.state.heatmapMin, this.state.heatmapMax]}
                                        step={0.1}
                                        min={-10}
                                        max={10}
                                        onChange={value => this.setState({heatmapMin: value[0], heatmapMax: value[1]})}
                                        onChangeComplete={value => {
                                            setHeatmapMin(value[0])
                                            setHeatmapMax(value[1])
                                        }}
                                    />
                                </Col>
                                <Col span={8}>
                                    <InputNumber
                                        style={{width: '75%', marginLeft: 16}}
                                        min={-10}
                                        max={10}
                                        step={0.1}
                                        value={this.state.heatmapMax}
                                        onChange={
                                            value => {
                                                if (!isNaN(value)) {
                                                    const rounded = _.round(value ? value : 0, 1)
                                                    this.setState({heatmapMax: rounded})
                                                    setHeatmapMax(rounded)
                                                } else {
                                                    console.log(`Bad field: ${value}`)
                                                }
                                            }
                                        }
                                    />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                    <Col span={searchedExperiment && searchedExperiment.protein ? 24 : 18}>
                        <Row gutter={8}>
                            {searchedExperiment && (searchedExperiment.compound || searchedExperiment.protein) ?
                                <Volcano
                                    hits={this.state.hitData}
                                    token={this.props.token}
                                    minCount={this.state.minCount}
                                    minReplicates={this.state.minReplicataes}
                                />
                                : null}
                        </Row>
                        <Row>
                            {this.state.hits.length && !Boolean(filterLabel) ?
                                <Heatmap
                                    token={this.props.token}
                                    hits={this.state.hits}
                                    colorScheme={heatmapColorSelected}
                                    minCount={this.state.minCount}
                                    minReplicates={this.state.minReplicates}
                                /> : null}
                        </Row>
                    </Col>
                </Row>
                {this.state.hits.length && !Boolean(filterLabel) ?
                    <Row>
                        <Table
                            style={{marginTop: 30, width: '100%'}}
                            columns={this.columns}
                            dataSource={_.map(this.state.hits, (hit, index) => {
                                return {
                                    ...hit,
                                    key: index
                                };
                            })}
                        />
                    </Row>
                    : null}
            </>

        } else {
            return <>
                <Typography>
                    <Title level={4}>Welcome to the Fischer Lab’s Proteomics Database</Title>
                    <Paragraph>
                        The goal of this proteomics webtool is to provide a growing open-access resource for
                        quantitative global proteome responses from cell lines treated with various compounds and novel
                        chemical probes.
                    </Paragraph>
                    <Paragraph>
                        To access the database please signup using a gmail address as we are currently only supporting
                        gmail as means of authentication.
                        After registration it will take up to 72 hrs for us to approve your request, and once approved
                        you will have full access to the database via this webtool and also via a REST API.
                    </Paragraph>
                    <Paragraph>
                        Further instructions can be found on our lab website.
                    </Paragraph>
                    <Title level={4}>Fischer Lab Proteomics Database Bulletin Board</Title>
                    <Paragraph>
                        To subscribe to the Fischer lab’s proteomics database bulletin board for updates on future data
                        release please email: {<a
                        href={'mailto:fischerlab-proteomics+subscribe@crystal.harvard.edu'}>fischerlab-proteomics+subscribe@crystal.harvard.edu</a>}
                    </Paragraph>
                    <Paragraph>
                        Archived messages can be browsed and searched at the list homepage: <a
                        href={'https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics'}>https://groups.google.com/a/crystal.harvard.edu/g/fischerlab-proteomics</a>
                    </Paragraph>
                </Typography>
                <div>
                    <Modal
                        title="DFCI Proteomics"
                        open={this.props.showLogin}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={null}
                        style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                    >
                        <GoogleButton onClick={this.handleOk} />
                    </Modal>
                </div>
            </>
        }
    }

}

const mapStateToProps = ({experiments, results, token}) => {
    return {
        experiments,
        results,
        showLogin: token.showLogin,
        token: token.token
    }
}

export default connect(mapStateToProps, {
    getResultDataRequest,
    getAllCompounds,
    getAllProteins,
    setHeatmapMax,
    setHeatmapMin,
    setVolcanoX,
    setVolcanoYMin,
    setVolcanoYMax,
    setVolcanoP,
    setVolcanoFC,
    setVolcanoUnique,
    setVolcanoType,
    setClickedAsync,
    setLoading,
    addCustomProtein,
    addCustomCompound,
    setShowLabel,
    setSearchedExperiment,
    toggleLoginModal,
    setMinCount,
    setMinReplicates
})(Home)