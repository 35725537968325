import React, { useState } from 'react';
import _ from 'lodash'
import Plot from 'react-plotly.js';
import { Spin, Typography } from 'antd'
import { BoxPlotTwoTone } from "@ant-design/icons";
import { connect } from 'react-redux'
import { getResultDataRequest } from '../features/experiments/experimentsActions'
import { setClicked, setLoadingAsync } from '../features/results/resultsActions'
import { SMILESChemicalStructure } from "../shared/components";
import { BACKEND_URL } from "../shared/constants";
import htmx from "htmx.org";

const { Text, Title } = Typography;

function Volcano({
    experiments,
    results,
    hits,
    token,
    getResultDataRequest,
    setClicked,
    setLoadingAsync,
    minCount,
    minReplicates
 }) {
    const [selectedChemicalStructureSVG, setSelectedChemicalStructureSVG] = useState('');
    const [peptideBoxPlotGeneId, setPeptideBoxPlotGeneId] = useState(-1);

    const {searchedExperiment} = experiments;

    const experimentTitle = searchedExperiment.meta.name
    const filterTitle = searchedExperiment.meta.filter_name
    const filterLabel = searchedExperiment.meta.filter_label

    const rawExperimentData = !filterLabel && results.volcanoUnique ? _.filter(searchedExperiment.data, obj => {
        return obj.unique_peptides >= results.volcanoUnique
    }) : searchedExperiment.data

    const experimentData = rawExperimentData.map(obj => {
        const newObject = { ...obj };
        newObject['gene_symbol'] = newObject['gene_symbol'].split(';')[0];
        return newObject;
    })

    const {volcanoType, volcanoFC, volcanoP, volcanoX, volcanoYMin, volcanoYMax} = results

    const useDefault = _.isNil(volcanoFC) || _.isNil(volcanoP) || _.isNil(volcanoType)

    const defaultPiUp = !_.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_up : Math.log2(volcanoFC) * volcanoP) : 0
    const defaultPiDown = !_.isEmpty(experimentData) ? (useDefault ? experimentData[0].default_down : Math.log2(volcanoFC) * volcanoP * -1) : 0

    const logPValues = _.map(experimentData, 'neg_log10_p_value')
    const logFCValues = _.map(experimentData, 'log_fc')

    const colors = filterLabel ? _.map(experimentData, () => 'blue') : _.map(hits, (item => {
        return item ? 'red' : 'blue'
    }))

    const labels = filterLabel ? _.map(experimentData, () => '') : _.map(hits, ((item, index) => {
        return item && experimentData[index] ? experimentData[index].gene_symbol : ''
    }))

    const minX = _.min(logPValues) - 0.25
    const maxXDefault = _.max(logPValues) + 0.25

    const minY = _.min(logFCValues) - 0.25
    const maxY = _.max(logFCValues) + 0.25

    const maxX = 0
    const xList = volcanoType === 'square' ? _.range(minX, 0.5, 0.1) : _.range(minX, maxX, 0.1)
    const yList = _.range(minY, maxY, 0.1)

    const yUpList = _.map(xList, x => volcanoType === 'square' ? Math.log2(volcanoFC) : -defaultPiUp / x)
    const yDownList = _.map(xList, x => volcanoType === 'square' ? -1 * Math.log2(volcanoFC) : -defaultPiDown / x)

    const xZoom = _.isNil(volcanoX) ? maxXDefault : volcanoX

    const yZoomMin = _.isNil(volcanoYMin) ? minY : volcanoYMin
    const yZoomMax = _.isNil(volcanoYMax) ? maxY : volcanoYMax

    const squareFinal = !filterLabel && volcanoType === 'square' ? {
        type: 'line',
        mode: 'lines',
        x: _.map(yList, y => volcanoP),
        y: yList,
        line: {
            shape: 'spline',
            dash: 'dashdot',
            color: 'gray',
            width: 2,
        },
        connectgaps: true,
        hoverinfo: 'none'
    } : {}

    if (_.isNil(experimentData.length)) {
        return <Spin style={{marginTop: 10}}/>
    }

    const data = [
        {
            name: '',
            type: 'scatter',
            mode: results.showLabel ? 'markers+text' : 'markers',
            text: labels,
            textposition: 'top center',
            x: logPValues,
            y: logFCValues,
            marker: {
                color: colors,
                opacity: 0.5,
                size: 6,
                line: {
                    color: ['black']
                }
            },
            hovertemplate: '<b>Log<sub>2</sub>FC:</b> %{y}<br />' +
                '<b>Log<sub>10</sub>P-Value:</b> %{x}<br />' +
                '<b>Treatment:</b> %{customdata.treatment_name}<br />' +
                '<b>Compound:</b> %{customdata.compound_name}<br />' +
                '<b>Accession:</b> %{customdata.accession_name}<br />' +
                '<b>Gene:</b> %{customdata.gene_symbol}<br />' +
                '<b>Unique Peptides:</b> %{customdata.unique_peptides}<br />' +
                '<b>Experiment Type:</b> %{customdata.experiment_type}' +
                `${searchedExperiment.meta.filter_label ? '<br /><b>Cell Line:</b> %{customdata.cell_line}' : ''}` +
                `${searchedExperiment.meta.filter_label ? '<br /><b>Experiment:</b> %{customdata.experiment_name}' : ''}`,
            customdata: experimentData,
            hoverlabel: {
                bgcolor: "white",
                font_size: 16,
                font_family: "Rockwell"
            }
        },
        filterLabel ? {} : {
            type: 'line',
            mode: 'lines',
            x: xList,
            y: yUpList,
            line: {
                shape: 'spline',
                dash: 'dashdot',
                color: 'gray',
                width: 2,
            },
            connectgaps: true,
            hoverinfo: 'none'
        },
        filterLabel ? {} : {
            type: 'line',
            mode: 'lines',
            x: xList,
            y: yDownList,
            line: {
                shape: 'spline',
                dash: 'dashdot',
                color: 'gray',
                width: 2,
            },
            connectgaps: true,
            hoverinfo: 'none'
        },
        squareFinal,
    ]

    return <>
        <Plot
            config={{
                displaylogo: false,
                responsive: true,
                toImageButtonOptions: {
                    format: 'svg',
                    filename: `${filterLabel ? filterLabel.split(';')[0] + " - (" + filterTitle + ")" : filterTitle} - ${experimentTitle}`
                }
            }}
            onClick={(e) => {
                const data = _.filter(e.points, element => element.data.type === 'scatter')[0];

                setLoadingAsync(true).then(() => {
                    if (!Boolean(filterLabel)) {
                        getResultDataRequest(
                            token,
                            data.customdata.accession_id,
                            'protein',
                            undefined,
                            undefined,
                            true
                        ).then((resp) => {
                            if (resp) {
                                setClicked(true);
                            }
                        });
                    } else {
                        getResultDataRequest(
                            token,
                            data.customdata.treatment_id,
                            'treatment',
                            minCount,
                            minReplicates,
                            true
                        ).then((resp) => {
                            if (resp) {
                                setClicked(true);
                            }
                        });
                    }
                });
            }}
            data={data}
            layout={{
                hovermode: 'closest',
                title: `${filterLabel ? filterLabel.split(';')[0] + " - (" + filterTitle + ")" : filterTitle} - ${experimentTitle}`,
                autosize: true,
                showlegend: false,
                xaxis: {
                    title: `Log<sub>10</sub> P Value`,
                    range: [minX, xZoom],
                },
                yaxis: {
                    title: `Log<sub>2</sub> FC Value`,
                    range: [yZoomMin, yZoomMax],

                }
            }}
            useResizeHandler={true}
            style={{width: !filterLabel ? '100%' : '75%', display: 'inline-block'}}
            onHover={(data) => {
                if (filterLabel) {
                    setSelectedChemicalStructureSVG(data.points[0].customdata.chemicalStructureSVG);
                } else if (data.points[0].customdata) {
                    setPeptideBoxPlotGeneId(data.points[0].customdata.gene_id);
                    htmx.trigger('#peptideBoxPlotRequestForm', 'changed');
                }
            }}
            onUnhover={() => setSelectedChemicalStructureSVG('')}
        />
        {filterLabel ?
            selectedChemicalStructureSVG ?
                <div style={{width: '25%', display: 'inline-block'}}>
                    <SMILESChemicalStructure chemicalStructureSVG={selectedChemicalStructureSVG}/>
                </div>
                : ''
            : <div
                style={{
                    height: '466px',
                    overflow: 'hidden',
                    position: 'relative',
                    border: '1px solid #ebedf0',
                    borderRadius: 2,
                    padding: '0.5em',
                    textAlign: 'center',
                    background: '#fafafa',
                    marginBottom: '1em',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}
            >
                <form id={'peptideBoxPlotRequestForm'}
                      style={{display: 'none'}}
                      hx-get={`${BACKEND_URL}/peptide_box_plot`}
                      hx-swap={'innerHTML'}
                      hx-ext={'json-enc'}
                      hx-target={'#peptideBoxPlot'}
                      hx-indicator={'#peptideBoxPlotLoadingIndicator'}
                      hx-trigger={'changed delay:0.66s'}
                >
                    <input type={'text'}
                           name={'experimentId'}
                           value={searchedExperiment.meta.id}
                           readOnly
                    />
                    <input type={'text'}
                           name={'geneId'}
                           value={peptideBoxPlotGeneId}
                           readOnly
                    />
                    <input type={'text'}
                           name={'minCount'}
                           value={minCount}
                           readOnly
                    />
                    <input type={'text'}
                           name={'minReplicates'}
                           value={minReplicates}
                           readOnly
                    />
                </form>
                <div style={{
                    height: '100%',
                    alignContent: 'center',
                    minWidth: '700px'
                }}>
                    <div id={'peptideBoxPlot'}>
                        <Text type={'secondary'} strong>
                            <BoxPlotTwoTone style={{fontSize: '5em'}} />
                        </Text>

                        <Title type={'secondary'} level={4} underline>
                            Hover over a point in scatter plot above to load related peptide box plot.
                        </Title>
                    </div>

                    <div id={'peptideBoxPlotLoadingIndicator'} className={'htmx-indicator'}>
                        <Text type={'secondary'} strong>
                            <BoxPlotTwoTone style={{fontSize: '5em'}} />

                            <br />

                            Generating box plot...
                        </Text>

                        <Spin style={{marginTop: '1em'}}/>
                    </div>
                </div>
            </div>}
    </>
}

const mapStateToProps = ({experiments, results}) => {
    return {
        experiments,
        results,
    }
}

export default connect(mapStateToProps, {
    getResultDataRequest,
    setClicked,
    setLoadingAsync,
})(Volcano)